/* Add to src/components/RecentIngredientsModal/RecentIngredientsModal.css */

.empty-state-message {
    text-align: center;
    padding: 20px;
    color: var(--text-color, #666); /* Fallback color if variable is not defined */
    font-size: 1rem;
    margin: 20px auto;
  }
  
  /* Ensuring other styles are simplified and do not distract from the main content */
  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: auto;
    max-width: 500px;
  }
  
  .modal-instructions, .modal-close-button {
    text-align: center;
    margin-top: 20px;
  }
  
  .modal-close-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #ccc;
    cursor: pointer;
  }
  
  .suggested-ingredient-list {
    max-height: 400px; /* Example: if each item is approx 40px in height, including padding/margin */
    overflow-y: auto;
    margin: 10px 0; /* Adjust based on your design */
  }
  
  .ingredient-item {
    padding: 10px;
    margin: 5px 0; /* Ensure this matches your desired spacing */
    cursor: pointer; /* Optional: Improves user interaction */
    background-color: #f7f7f7; /* Optional: For better visibility */
    border-radius: 4px; /* Optional: For styling */
  }

  .modal-content {
    overflow: hidden; /* Prevents the modal from becoming scrollable */
    display: flex;
    flex-direction: column;
  }
  