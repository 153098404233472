.modal {
    background: var(--tertiary-bg-color);
    max-width: 600px;
    margin: auto;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px var(--shadow-intense-color);
    position: relative;
}

.modal__link {
    text-decoration: none;
    color: var(--secondary-color);
    padding: 8px 16px;
    border-radius: 5px;
    transition: color 0.3s ease;
    background-color: transparent;
}

.modal__link:hover {
    color: var(--primary-color);
    background-color: transparent;
}

.modal .close {
    padding: 4px;
    font-size: 33px;
    font-weight: 500;
    display: flex;
    background: transparent;
    color: var(--tertiary-color);
    line-height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
}

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    max-width: 90%;
    max-height: 90%;
}

.modal__content {
    max-height: 60vh;
    overflow-y: scroll;
}

.modal__content a {
    word-break: break-word;
}

.modal__content li a {
    color: var( --secondary-color);
    transition: color 0.2s;
}

.modal__content li a:hover {
    color: var(--secondary-dark-color);
}
  