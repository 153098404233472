/* IngredientSelector.css */

.ingredient-selector {
    margin: 20px 0;
  }
  
  /* Styles for the react-select dropdown */
  .ingredient-select__control {
    background-color: var(--tertiary-bg-color);
    border: 1px solid var(--light-border-color);
    border-radius: 4px;
    padding: 4px;
    font-family: var(--primary-font);
    font-size: 1rem;
  }
  
  .ingredient-select__control--is-focused {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
  }
  
  .ingredient-select__value-container {
    padding: 2px 8px;
  }
  
  .ingredient-select__placeholder {
    color: var(--lighter-neutral-color);
  }
  
  .ingredient-select__single-value {
    color: var(--neutral-color);
  }
  
  .ingredient-select__menu {
    background-color: var(--tertiary-bg-color);
    border: 1px solid var(--light-border-color);
    box-shadow: 0 4px 8px var(--shadow-intense-color);
    font-family: var(--primary-font);
  }
  
  .ingredient-select__option {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .ingredient-select__option--is-focused,
  .ingredient-select__option:active {
    background-color: var(--primary-color);
    color: var(--tertiary-bg-color);
  }
  
  .ingredient-select__option--is-selected {
    background-color: var(--secondary-color-dark);
    color: var(--tertiary-bg-color);
  }
  