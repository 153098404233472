.benefits {
    background: var(--secondary-color-dark);
    color: white;
    padding: 40px 0;
}

.benefits__title {
    margin-top: 0;
}

.benefits__desc {
    margin-bottom: 0;
}

.benefits__benefits-wrapper {
    display: grid;
    gap: 40px;
}

.benefits__single {
    max-width: 90%;
}

.benefits__single:nth-of-type(even) {
    text-align: right;
    margin-left: auto;
}

@media all and (min-width: 768px) {
    .benefits__single {
        max-width: 60%;
    }
}

@media all and (min-width: 992px) {
    .benefits {
        padding: 80px 0;
    }

    .benefits__single {
        text-align: center;
        max-width: 100%;
    }

    .benefits__single:nth-of-type(even) {
        text-align: center;
        margin-left: unset;
    }

    .benefits__benefits-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}