/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --primary-font: 'Poppins', sans-serif;
  --primary-bg-color: #f4f4f4;
  --secondary-bg-color: #f7f7f7;
  --tertiary-bg-color: #fff;
  --primary-color: #fdb11c;
  --secondary-color: #00550d;
  --secondary-color-dark: #00400a;
  --tertiary-color: #2d2d2d;
  --neutral-color: #333;
  --light-neutral-color: #555;
  --lighter-neutral-color: #999;
  --border-color: #e5e5e5;
  --light-border-color: #ddd;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --shadow-intense-color: rgba(45, 45, 45, 0.2);
  --positive-feedback-color: #d4edda; /* subtle green */
  --negative-feedback-color: #f8d7da; /* subtle red */
  --positive-feedback-hover-color: #28a745; /* more prominent green */
  --negative-feedback-hover-color: #dc3545; /* more prominent red */
}

body {
  font-family: var(--primary-font);
  background-color: var(--primary-bg-color);
  color: var(--neutral-color);
  line-height: 1.5;
  margin: 0;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

h3 {
  font-size: 1.125rem;
  font-weight: bold;
}

.container {
  max-width: 1000px;
  padding: 24px 15px 30px;
  margin: 0 auto 20px;
  background-color: var(--tertiary-bg-color);
  box-shadow: 0 10px 20px var(--shadow-color);

  @media all and (min-width: 992px) {
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 40px;
  }
}

button {
  background-color: var(--primary-color);
  border: none;
  font-size: 0.9em;
  font-weight: 600;
  color: var(--tertiary-bg-color);
  padding: 9px 24px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
  outline: none;
}

.control-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  width: 100%;
}

.control-buttons__pagination svg {
  height: 16px;
}

.button__copy svg {
  margin-right: 6px;
}

button.add-ingredient-btn:hover:not([disabled]),
button:hover:not([disabled]):not(.modal__link) {
  background-color: rgba(255, 156, 0, 0.75);
}

button.generate-recipe-btn:hover:not([disabled]) {
  background-color: var(--secondary-color-dark);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#rcc-confirm-button {
  transition: background-color 0.2s;
}

.restriction-item {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--primary-bg-color);
  transition: background-color 0.5s ease;
  margin: 5px;
  font-weight: 500;
}

.restriction-item:hover {
  background-color: var(--border-color);
}

.restriction-item.selected {
  background-color: var(--primary-color);
  color: var(--tertiary-bg-color);
}

@keyframes fadeInSlideRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.ingredient {
  animation: fadeInSlideRight 500ms forwards;
}

.ingredient,
.ingredient-add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  background-color: var(--primary-bg-color);
}

.ingredient-add {
  display: flex;
  column-gap: 12px;
  margin-bottom: 30px;
}

.ingredient .ingredient__name {
  flex-grow: 1;
  padding: 0 10px;
}

.ingredient .ingredient__delete {
  color: var(--lighter-neutral-color);
  margin-right: 10px;
  transition: color 0.2s;
}

.ingredient .ingredient__delete:hover {
  color: var(--light-neutral-color);
  cursor: pointer;
}

.ingredient .ingredient__delete svg {
  height: 15px;
}

.ingredient-input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid var(--light-border-color);
  border-radius: 4px;
}

.ingredient-input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 10px var(--shadow-intense-color);
}

.add-ingredient-btn {
  background-color: var(--primary-color);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.recipe-section {
  margin-top: 30px;
  padding: 16px 16px 24px 16px;
  background: var(--secondary-bg-color);
  border-radius: 10px;
}

.recipe-instructions {
  padding: 20px;
  border: 1px solid var(--border-color);
  background-color: var(--tertiary-bg-color);
  font-size: 17px;
  line-height: 1.6;
  border-radius: 10px;
  color: var(--light-neutral-color);
  white-space: pre-line;
}

@media all and (min-width: 992px) {
  .recipe-instructions  {
    font-size: 18px;
  }
}

.recipe-instructions.empty {
  text-align: center;
  color: var(--lighter-neutral-color);
}

.fa-trash {
  color: var(--lighter-neutral-color);
  transition: color 0.2s;
}

.fa-trash:hover {
  color: var(--light-neutral-color);
  cursor: pointer;
}

.recipe-wrapper {
  gap: 2rem;
  display: grid;

  @media all and (min-width: 992px) {
    grid-template-columns: 30% 1fr;
  }
}

.recipe-details-card {
  @media all and (min-width: 992px) {
    grid-column: 1/3;
  }
}

.suggested-ingredient-list div {
  border-bottom: 1px solid var(--light-border-color);
  padding: 5px 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.suggested-ingredient-list div:hover {
  background-color: var(--primary-color);
  color: var(--tertiary-bg-color);
}

@media all and (min-width: 992px) {
  .recipe-ingredients-card,
  .suggested-ingredients-card {
    grid-column: 1/2;
  }

  .recipe-instructions-card {
    grid-column: 2/3;
    grid-row: 2/4;
  }
}

.recipe-details-card,
.suggested-ingredients-card {
  background-color: var(--secondary-bg-color);
  border-radius: 4px;
  padding: 10px 15px;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.recipe-details-card h3 {
  color: var(--neutral-color);
}

.recipe-content {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.recipe-card {
  background: var(--tertiary-bg-color);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px var(--shadow-color);
}

.recipe-card h2 {
  margin-top: 0;
}

.preserve-whitespace {
  white-space: pre-line;
}

.loader-container {
  background-color: var(--tertiary-bg-color);
  padding: 20px;
  box-shadow: 0 2px 10px var(--shadow-color);
  color: var(--neutral-color);
  margin: 0;
  text-align: left;
}

.suggested-ingredient-list .suggested-alternative-item {
  width: 100%;
  margin-bottom: 6px;
  padding: 8px 6px;
  box-sizing: border-box;
}

.suggested-alternative-item:hover {
  border-color: var(--primary-color);
}

.fact-header {
  margin-bottom: 10px;
  color: var(--light-neutral-color);
}

.fact-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.spinner-container {
  display: flex;
  justify-content: left;
  align-items: center;
}

.autocomplete-wrapper {
  position: relative;
}

.shadow-input {
  position: absolute;
  top: 0;
  left: 0;
  color: #aaa;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
}

/* Add this at the end of your App.css */

.generate-recipe-btn {
  background-color: var(--primary-color);
  background-color: var(--secondary-color);
  color: var(--tertiary-bg-color);
  font-family: var(--primary-font);
  font-size: 1rem;
  font-weight: 600;
  padding: 12px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-bottom: 20px; /* Adjust as needed */
  width: 100%;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.generate-recipe-btn.disabled:hover {
  background-color: var(--secondary-color);
}

.recipe-ingredients-dropdown {
  flex-grow: 1; /* Grow to fill the space */
}

.recipe-ingredients {
  display: flex;
  flex-direction: column;
}

.recipe-ingredient-list {
  padding: 0 0 25px 15px;
  margin: 0 0 25px;
  border-bottom: 1px solid var(--border-color);
}

.recipe-ingredient-list li {
  margin-bottom: 3px;
}

.recipe-ingredient-list li:last-child {
  margin-bottom: 0;
}

.recipe-ingredient-list li::marker {
  color: var(--secondary-color);
  font-size: 18px;
}

.see-suggested-ingredients-btn {
    padding: 12px 10px;
    margin: auto 0 0 0;
    display: block;
    width: 100%;
}

.ingredient-selection {
  display: flex;
  align-items: center; /* Align the items by their vertical center */
  width: 100%; /* Full width */
}

.recent-ingredients-btn {
  padding: 0; /* Reset padding to control height */
  width: 40px; /* Set width for square shape */
  aspect-ratio: 1; /* Ensures the width and height are always equal, forming a square */
  margin-left: 10px; /* Space between dropdown and button */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  border: none; /* Adjust as needed */
  color: var(--tertiary-bg-color);
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--shadow-color); /* Optional: if you want to add shadow to match other elements */
}

@media (max-width: 768px) {
  .ingredient-selection {
    flex-direction: column;
    align-items: stretch; /* Stretch children on small screens */
  }

  .recent-ingredients-btn {
    margin-left: 0;
    margin-top: 10px; /* Add space between dropdown and button */
    width: auto; /* Button takes full width on small screens */
    height: 40px; /* Set a fixed height */
  }
}

.ingredient-selection > *:not(:last-child) {
  flex-grow: 1; /* Ensure the dropdown takes up the remaining space */
}

.controls-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recipe-header {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
}

.recipe-header h3 {
  margin: 0;
  font-size: 17px;
}

.recipe-header h2 {
  margin: 0 0 10px 0;
}

.recipe-header p {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 13px;
}

.additional-note {
  font-size: 12px;
  color: #c5c5c5;
  margin: 30px 0 0;
}

.blocks-wrapper {
  padding: 0 20px;
  max-width: 1080px;
  margin: auto;
}

@keyframes shake {
  0% { transform: translateX(0px); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0px); }
}

.shake-animation {
  animation: shake 0.5s;
}

.recipe-instructions__items {
  padding: 0 10px 0 22px;
}

@media all and (min-width: 992px) {
  .recipe-instructions__items {
    padding: 0 20px 0 28px;
  }
}

.recipe-instructions__items li {
  margin-bottom: 16px;
  padding-left: 3px;
}

.recipe-instructions__items li::marker {
  font-weight: 600;
}
