.modal-overlay {
    position: fixed;
    z-index: 1000; /* Increased to ensure modal is on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center; /* Center modal vertically */
    justify-content: center; /* Center modal horizontally */
    background-color: rgba(0, 0, 0, 0.6); /* Darker background */
    animation: fadeIn 0.3s ease-out; /* Fade-in effect for the overlay */
}

.modal-content {
    position: relative;
    background-color: var(--tertiary-bg-color);
    padding: 20px;
    border-radius: 10px; /* Rounded corners */
    width: 90%; /* Optimized for mobile */
    max-width: 500px; /* Maximum width to avoid too wide modals on large screens */
    box-shadow: 0 4px 6px var(--shadow-intense-color); /* More intense shadow for depth */
    animation: slideUp 0.3s ease-out; /* Slide-up effect */
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.suggested-ingredient-list .ingredient-item {
    cursor: pointer;
    padding: 8px;
    border-bottom: 1px solid var(--light-border-color);
    color: var(--neutral-color);
    transition: all 0.2s ease;
}

.suggested-ingredient-list .ingredient-item:hover {
    background-color: var(--primary-color);
    color: var(--tertiary-bg-color);
    border-color: var(--primary-color);
}

.modal-content .modal-close-button {
    display: block; /* Ensure it's easily clickable on mobile */
    width: 100%; /* Full width for easier access */
    margin-top: 20px;
    padding: 12px;
    background-color: var(--secondary-color); /* Using CSS variable */
    color: var(--tertiary-bg-color); /* Using CSS variable for contrast */
    border: none;
    cursor: pointer;
    border-radius: 4px; /* Consistency with your button styles */
    transition: background-color 0.2s ease;
}

.modal-content .modal-close-button:hover {
    background-color: var(--secondary-color-dark); /* Slightly darker on hover */
}

/* Responsive adjustments for larger screens */
@media (min-width: 768px) {
    .modal-content {
        width: 60%; /* Wider on larger screens */
        margin: 15% auto; /* Adjusted positioning */
    }
}
