.steps {
    padding: 40px 0;

    @media all and (min-width: 992px) {
        padding: 80px 0;
    }
}

.steps__img {
    text-align: center;

    @media all and (min-width: 992px) {
        text-align: start;
        padding-left: 0;
        margin: 30px;
    }
}

.steps__img img {
    max-width: 100%;
    max-height: 420px;
}

.steps__title {
    display: flex;
    align-items: center;
    margin-top: 0;
}

.steps__title-number {
    font-size: 60px;
    line-height: 60px;
    font-weight: 400;

    @media all and (min-width: 992px) {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
    }
}

.steps__title-text {
    padding-left: 16px;
}

.steps__steps-wrapper {
    display: grid;
    gap: 40px;

    @media all and (min-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.steps__single {
    @media all and (min-width: 992px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media all and (min-width: 992px) {
    .steps {
        padding: 80px 0;
    }
    
    .steps__img {
        text-align: start;
        padding-left: 0;
        margin: 30px;
    }
    
    .steps__title-number {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
    }

    .steps__steps-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .steps__single {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}