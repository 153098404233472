.recipe-ingredients-root {
    width: 100%; /* Ensure it takes the full width */
}

.ingredient-selector-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; /* This ensures elements are spaced out */
    width: 100%;
}

.ingredient-selector {
    flex-grow: 1;
    margin-right: 10px; /* Adjust if necessary but keep it for spacing */
}

.recent-ingredients-btn {
    flex-shrink: 0; /* Keep this to prevent the button from shrinking */
}

@media (max-width: 768px) {
    .ingredient-selector {
        margin-right: 5px; /* Slightly reduce margin for small devices */
    }

    /* Remove flex-direction: column to keep elements inline */
    .recent-ingredients-btn {
        margin-top: 0; /* Remove margin-top adjustment for mobile */
        /* Adjust button size if necessary but maintain inline display */
    }
}
