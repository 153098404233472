.content {
    background: var(--secondary-color-dark);
    color: white;
    padding: 40px 0;
}

.content__title {
    margin-top: 0;
}

.content__content-wrapper {
    text-align: center;
}

@media all and (min-width: 992px) {
    .content {
        padding: 80px 0;
    }
}