.hero {
    background: var(--secondary-color-dark);
    color: white;
    padding: 40px 0;
}

.hero__title {
    line-height: 40px;
    margin-top: 0;
}

.hero__desc {
    margin-bottom: 30px;
    font-size: 18px;
}

.hero__img {
    text-align: center;
}

.hero__img img {
    max-width: 100%;
    max-height: 420px;
}

.hero__btn-wrapper {
    display: flex;
    justify-content: center;
}

.hero__btn-wrapper--desktop {
    display: none;
}

.hero__btn {
    background-color: var(--primary-color);
    border: none;
    font-size: 21px;
    font-weight: 600;
    color: var(--secondary-color-dark);
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 50px;
    transition: background-color 0.2s;
    text-decoration: unset;
    display: inline-block;
    width: 280px;
    text-align: center;
}

.hero__btn:hover {
    background-color: rgba(255, 156, 0, 0.85);
}

.hero__btn-wrapper--mobile {
    margin-bottom: 40px;
}

@media all and (min-width: 992px) {
    .hero {
        padding: 80px 0;
    }

    .hero__img {
        text-align: right;
    }

    .hero__wrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 40px 20px;
    }

    .hero__content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    .hero__btn-wrapper {
        justify-content: flex-start;
    }

    .hero__btn-wrapper--desktop {
        display: block;
    }
    
    .hero__btn-wrapper--mobile {
        display: none;
    }
}
