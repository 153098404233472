.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: var(--primary-bg-color);
    position: relative;
}

.navbar-logo {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-decoration: none;
    transition: opacity 0.3s;
}

.navbar-logo:hover {
    opacity: 0.8;
}

.navbar-links {
    display: flex;
    font-weight: 500;
    gap: 60px; /* Adjust if needed based on your design preference */
    list-style: none;
    padding: 0;
    margin: 0;
}

.navbar-link {
    text-decoration: none;
    color: var(--secondary-color-dark);
    transition: color 0.2s;
}

.navbar-link:hover {
    color: var(--secondary-color);
}

/* Account Section Separator */
.auth-controls {
    margin-left: auto;
    padding-left: 20px;
    border-left: 2px solid var(--light-border-color); /* Creates a visual separator */
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust based on your layout */
}

/* User Email Style */
.user-email {
    font-weight: normal;
    color: var(--neutral-color);
    background-color: transparent;
    padding: 5px 8px;
    border-radius: 15px;
}

/* Sign-out Link Style */
.sign-out-link {
    padding: 5px 8px;
    border-radius: 15px;
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
}

.sign-out-link:hover, .sign-out-link:focus {
    background-color: var(--secondary-color);
    color: var(--tertiary-bg-color);
}

@media all and (min-width: 992px) {
    .navbar {
        padding: 10px 30px;
        margin-bottom: 10px;
    }
}

@media all and (max-width: 991px) {
    .navbar-links, .auth-controls {
        display: none;
    }
    /* Additional styles for responsive design might be needed here */
}
