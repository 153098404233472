/* Footer styles */
.app-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    flex-direction: column;
    padding: 30px 20px 30px;
    font-size: 0.9em;
    text-align: center;
}
  
.heart {
    color: var(--primary-color);
    margin: 0 5px;
}

.footer-menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.footer-menu .modal__link {
    padding: 5px 16px;
    color: black;
    transition: color 0.2s;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--secondary-color);
}

.footer-menu .modal__link:hover, .icons8-link:hover {
    color: var(--secondary-color-dark);
}

.icons8-link {
    color: var(--secondary-color);
    text-decoration: none;
}

.footer-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}

.socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.socials__social {
    line-height: 0;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socials__social svg {
    color: #1877F2;
    height: 20px;
    transition: transform 0.2s;
}

.socials__social:hover svg {
    transform: scale(1.1);
}
