.recipe-feedback {
    background-color: var(--tertiary-bg-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px var(--shadow-color);
}

@media all and (min-width: 992px) {
    .recipe-feedback {
        grid-column: 2 / 3;
    }
}

.recipe-feedback h2 {
    margin: 0 0 10px 0;
}

.recipe-feedback p {
    margin: 0;
}

.feedback-btn-container {
    display: flex;
    justify-content: space-between; /* This is correct for spacing, but let's ensure the container has a width */
    gap: 10px;
    width: 100%; /* Ensure the container takes full width */
    margin-top: 15px; /* Increased spacing from the text above */
}

.feedback-btn {
    flex: 1; /* This should make each button expand, but let's ensure there's no conflict */
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 600; /* Optional: Adjusts font weight for better readability */
}

.feedback-btn:disabled {
    background-color: var(--lighter-neutral-color);
    color: var(--tertiary-bg-color);
    cursor: not-allowed;
}

.feedback-btn.positive-feedback {
    background-color: var(--positive-feedback-color); /* Use the subtle green */
    color: var(--tertiary-color); /* Adjust the text color as needed */
}

.feedback-btn.negative-feedback {
    background-color: var(--negative-feedback-color); /* Use the subtle red */
    color: var(--tertiary-color); /* Adjust the text color as needed */
}

.recipe-feedback .feedback-btn.positive-feedback:hover {
    background-color: var(--positive-feedback-hover-color); /* Prominent green on hover */
    color: white;
}

.recipe-feedback .feedback-btn.negative-feedback:hover {
    background-color: var(--negative-feedback-hover-color); /* Prominent red on hover */
    color: white;
}

