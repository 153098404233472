.cta {
    background: var(--secondary-color-dark);
    color: white;
    padding: 40px 0;
}

.cta__title {
    margin-top: 0;
}

.cta__content-wrapper {
    text-align: center;
}

.cta__btn-wrapper {
    display: flex;
    justify-content: center;
}

.cta__btn {
    background-color: var(--primary-color);
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: var(--secondary-color-dark);
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 50px;
    transition: background-color 0.2s;
    text-decoration: unset;
    display: inline-block;
    width: 310px;
    text-align: center;
    margin-top: 20px;
    max-width: 100%;
}

.cta__btn:hover {
    background-color: rgba(255, 156, 0, 0.85);
}

.cta__additional-content {
    margin-top: 40px;
    font-size: 14px;
}

@media all and (min-width: 992px) {
    .cta {
        padding: 80px 0;
    }
}