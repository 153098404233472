/* client/src/components/LoginPromptModal/LoginPromptModal.css */

.login-prompt-content {
    text-align: center;
  }
  
  .login-prompt-text {
    margin-bottom: 20px;
  }
  
  .login-options {
    margin-bottom: 20px;
  }
  
  .login-prompt-footer {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .login-prompt-footer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .login-prompt-footer a:hover {
    text-decoration: underline;
  }
  