.nav-mobile__wrapper {
    position: fixed;
    inset: 0;
    background-color: var(--primary-bg-color);
    z-index: 99;
}

.hamburger-react {
    z-index: 100;
    color: var(--secondary-color-dark);
}

.nav-mobile__list {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    gap: 30px;
    padding: 80px 20px 0;
    margin: 0;
}

.nav-mobile__list-link {
    color: var(--secondary-color-dark);;
    text-decoration: unset;
    font-size: 22px;
    font-weight: 500;
}

@media all and (min-width: 992px) {
    .nav-mobile {
        display: none;
    }
}
