.cards__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 50px;
}

.card {
    box-shadow: 0 3px 3px rgba(4,27,10,.1);
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 10px;
    background-color: white;
}

.card__wrapper {
    max-width: 250px;
    margin: auto;
}

.card__content {
    padding: 20px;
}

.card__content h2 {
    margin-top: 0;
    font-size: 23px;
}

.card__image {
    position: relative;
    padding-top: 260px;
    margin: 0;
}

.card__image img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    inset: 0;
    object-fit: cover;
    position: absolute;
}

.card__link {
    text-decoration: none;
    color: #00400a;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card__link svg {
    transition: transform 0.2s;
}

.card__link:hover svg {
    transform: translateX(-10px);
}

.cards__title {
    margin-top: 40px;
    margin-bottom: 30px;
    text-align: center;
}

@media all and (min-width: 992px)  {
    .cards__wrapper {
        margin-top: 0;
    }

    .cards__title {
        display: none;
    }
}