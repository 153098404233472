.about {
    padding: 40px 0;
    max-width: 100%;
    overflow: hidden;
}

.about__content {
    text-align: center;
}

.about__title {
    margin-top: 0;
}

.about__img {
    text-align: center;
    margin-top: 36px;
}

.about__img img {
    max-width: 100%;
    max-height: 420px;
}

@media all and (min-width: 992px)  {
    .about {
        padding: 80px 0;
    }
    
    .about__content {
        order: 2;
        text-align: right;
        max-width: 90%;
        margin-left: auto;
    }
    
    .about__img {
        margin-top: 0;
    }
    
    .about__content-wrapper {
        display: grid;
        grid-template-columns: 30% 1fr;
        align-items: center;
    }
}
